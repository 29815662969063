html, body, #root{
  height: 100%;
}

.header{
  background-color:  #07345D;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header .title{
  color: #F0B719;
  margin: 0;
  padding: 30px;
  width: 35%;
}
.header .logo{
  width: 65%;
  display: flex;
}
.map-filters{
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  margin: auto;
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.controllers-1-desktop{
  width: 55%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.controllers-2{
  width: 30%;
  left: 35% ;
  padding-top: 25px;
}
.timer{
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 13px;
  width: 250px;
  justify-content: space-around;
}
.content-wrapper{
  position: relative;
  height: calc(100% - 88px);
}
.speed{
  display: flex;
  align-items: center;
  width: 250px;
  background-color: #fff;
  border-radius: 13px;
  padding: 20px 20px;
}
.speed .label{
  width:58%
}
.button-start{
  width: 250px;
    max-height: 76px;
    background-color: #F0B719!important;
    border: none!important;
    color: #000!important;
    font-size: 32px!important;
    border-radius: 10px!important;
}
.button-stop{
  width: 250px;
    max-height: 76px;
    background-color: #fff !important;
    border-color: #E02D2D !important;
    color: #E02D2D !important;
    font-size: 32px !important;
    border-radius: 10px !important;
}
.search-input{
  margin-top: 21px;
  background-color: #fff;
  border-radius: 10px;
}
.live-tracking-desktop-component{
  position: absolute;
  right: 15px;
  top: 200px;
  z-index: 99;
  background-color: #fff;
  border-radius: 20px;
  padding: 28px 14px;
  width: 246px
}
.leader-board{
  position: absolute;
    z-index: 9;
    background-color: #fff;
    left: 20px;
    top: 150px;
    width: 400px;
    padding: 29px 20px;
    border-radius: 20px;
    max-height: 400px;
    overflow-y: scroll;
}
.leader-board-mobile .leader-board{
  position: relative;
  width: 90%;
  left: 0;
  top: 0;
  margin: auto;
  max-height: 500px;
  padding-top: 0;
}
/* .leader-board-mobile .leader-board .title{
  display: none !important
} */
.name-circle{
  min-width: 70px;
  height: 70px;
  background-color: darkred;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #fff;
  font-weight: bold;
  position: relative;
  margin-right: 15px;
}
.name-circle:first-child{
  margin-left: 15px;
}
.live-tracking-mobile-component{
  position: absolute;
    top: 130px;
    z-index: 2;
    background-color: transparent;
    width: 100%;
    display: flex;
    overflow:auto;
    height: 88px;
    align-items: center;
    flex-wrap: nowrap;
}
.leader-board-mobile{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 70%;
  z-index: 9;
  background-color: #fff;
  border-radius: 30px 30px 0px 0px;
}
.bottom-line{
  height: 20px;
    text-align: center;
    margin-bottom: 5px;
    border-top-color: #f3f2f2;
    border-top-width: 1px;
    border-top-style: solid;
    padding-top: 5px;
}
.controllers-1-mobile{
  position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 126px;
    z-index: 9999;
    background-color: #fff;
}
.controllers-1-mobile .button{
  width: 105px;
  height: 105px;
  background-color: #F0B719!important;
  border: none!important;
  color: #fff!important;
  font-size: 32px!important;
  border-radius: 50% !important;
  position: absolute !important;
  top: -52px;
  left: calc(50% - 52.5px);
  filter: drop-shadow(0px 5.25px 21px rgba(135, 135, 135, 0.65));
}
.controllers-1-mobile .button.start{
  background-color: #F0B719!important;
  color: #fff!important;
}
.controllers-1-mobile .button.stop{
  background-color: #fff!important;
  color: #E02D2D!important;
}
.controllers-1-mobile .timer{
  display: block;
  text-align: center;
  width: auto;
}
.controllers-1-mobile .timer h2{
  margin-bottom: 0;
  margin-top: 10px;
}
.controllers-1-mobile .timer .title{
  font-size: 10px;
}

.MuiToggleButtonGroup-root {
  display: flex;
  border-radius: 0;
  width: 100%;
  justify-content: space-between;
}

.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped{
  background-color: #8A8D8A !important;
  width: 30%;
  color: #fff;
  border-radius: 50px!important;
  border-color: transparent;
  font-size: 29px;
  height: 53px !important;

}
.MuiButtonBase-root.MuiToggleButton-root.Mui-selected{
  color: #fff !important;
  background-color: #A84E4E !important;
}
.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px !important;
}

.MuiOutlinedInput-notchedOutline{
  border: none !important;
  margin-bottom: 200px;
}
.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
  color: #7D7D7D
}
@media (max-width: 576px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

@media (min-width: 577px ) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
}
@media (width: 576px) {
  .mobile {
    display: block;
  }
}
@media (max-width: 576px) {
  .map-filters{
    width: 100%;
    left: 0;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.75) 100%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 0;
  }
  .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
    width: 85px;
    font-size: 22px;
    height: 40px!important;
    box-shadow: 4px 2px 9px rgb(0 0 0 / 10%);
  }
  .MuiToggleButtonGroup-root {
    width: 80%;
    justify-content: space-around;
  }
  .search-input {
    margin-top: 10px;
    position: relative;
    margin-bottom: 10px;
  }
  .content-wrapper{
    height: 100%
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
    padding-left: 55px !important;
  }
  .controllers-2{
    width: 90%;
    left: 35%;
    padding-top: 25px;
    text-align: center;
  }
  .controllers-1-desktop{
    display: none;
  }
}

@media (min-width: 577px ) {
 .search-input{
  position: absolute;
  top: 80px;
  right: 18px;
  width: 270px;
 }
}